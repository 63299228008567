@import 'variable';

.ui-click-link,
.click-link {
  cursor: pointer;
  text-decoration: none;
  color: $blue-700;
}
.ui-cursor-pointer,
.cursor-pointer {
  cursor: pointer;
}

.container {
  max-width: 360px;
  margin: 0 auto;
}

.purposes-dialog .mat-dialog-container {
  background-color: #f5f5f5;
  .mat-dialog-content {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

// form error
.p_overlay-menu_action-buttons {
  .mat-mdc-form-field-error {
    margin-top: -10px;
    padding-right: 4px;
    padding-left: 4px;
    border: 1px solid $livmap-warn-500;
    background-color: $livmap-warn-50;
    border-radius: 4px;
  }
}

// snackbarにclassを付与して、白背景の黒文字のスタイルを当てる
.succeeded-snackbar {
  color: #252525;
  background: #fff;
  .mat-simple-snackbar-action {
    color: #1a98a7;
  }
}
.error-snackbar {
  color: #252525;
  background: #fff;
  .mat-simple-snackbar-action {
    color: #1a98a7;
  }
}

// アカウント関連のフォームで入力欄の背景色を指定
.libs-a-phone-number-confirmation .mat-mdc-text-field-wrapper,
.libs-a-phone-number-verification .mat-mdc-text-field-wrapper,
.signup-email-form .mat-mdc-text-field-wrapper {
  background-color: #ffffff;
}

// タイムライン、作業ログにて日付表示用ツールチップの横幅を指定
.date-custom-mat-tooltip .mdc-tooltip__surface {
  max-width: 400px;
}

.collaborators-management-action-button-menu {
  font-size: 14px;

  .delete {
    color: $orange-700;
  }

  .lock {
    color: $orange-500 !important;
  }
}

// Chipのオーバーフローを抑制するグローバル指定
.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}

// dialog全体のテイスト設定
.mat-mdc-dialog-surface {
  border-radius: 16px !important;
}

.collaborators-management-channels-dialog-form .channels .mat-mdc-list-option-checkbox-before {
  margin: 0;
}

.collaborators-management-channels-dialog-form .users .mat-mdc-list-item-unscoped-content,
.collaborators-management-role-dialog-form .users .mat-mdc-list-item-unscoped-content {
  display: flex;
}
