@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$livmap-green: (
  50: #f6faf6,
  100: #ecf5ed,
  200: #ecf5ed,
  300: #c8e0c9,
  400: #a2cca5,
  500: #46994b,
  600: #46994b,
  700: #46994b,
  800: #46994b,
  900: #46994b,
  A100: #46994b,
  A200: #46994b,
  A400: #46994b,
  A700: #46994b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$livmap-red: (
  50: #f44336,
  100: #f44336,
  200: #f44336,
  300: #f44336,
  400: #f44336,
  500: #f44336,
  600: #f44336,
  700: #f44336,
  800: #f44336,
  900: #f44336,
  A100: #f44336,
  A200: #f44336,
  A400: #f44336,
  A700: #f44336,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
// custom theme
$livmap-app-primary: mat.m2-define-palette($livmap-green);
$livmap-app-accent: mat.m2-define-palette(mat.$m2-red-palette, A200, A100, A400);
$livmap-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
$livmap-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $livmap-app-primary,
      accent: $livmap-app-accent,
      warn: $livmap-warn,
    ),
  )
);

@include mat.core-theme($livmap-app-theme);
:root {
  @include mat.all-component-themes($livmap-app-theme);
}

// todo 以下を整理する

// input にカーソルがフォーカスした時の枠線色とラベル色
.mat-focused .mat-form-field-label {
  color: #00796b !important;
}
.mat-form-field-ripple {
  background-color: #00796b !important;
}
.mat-focused.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #00796b;
}

.hidden-first-group-child {
  .mat-mdc-optgroup:first-child {
    .mat-mdc-optgroup-label:first-child {
      display: none;
    }
  }
}

.phone-codes {
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
}

//modal 見出し
.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  padding-bottom: 24px !important;
}
//modal 背景と幅
.mat-mdc-dialog-container .mdc-dialog__content {
  min-width: 420px;
}

// 地図設定ボタンのアイコンを上下中央に
.overlay-menu .mat-mdc-raised-button .mdc-button__label {
  display: flex;
  align-items: center;
}

// 地図表示時のタブを等幅にする
.map-style-tab .mdc-tab {
  flex: 1;
}

// 最近使用した地図のリスト表示内のボタンをflexにする
.overlay-menu-map-style .map-style-history .mdc-button__label {
  display: flex;
  flex-direction: column;
}

// inputでautofillが効いた際に背景色が指定外になる問題の対処
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--mdc-filled-text-field-input-text-color);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}
